/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { Box, MenuItem, Select, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AlertNotificationRangeType, AlertNotificationRangeUnitType } from '../types';

const useStyles = makeStyles((theme: Theme) => ({
    select: {
        width: '13ch',
        marginLeft: theme.spacing(1),
    },
    numberInput: {
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
    },
}));

interface RangeSelectorPropsType {
    range: AlertNotificationRangeType;
    onChange: (range: AlertNotificationRangeType) => void;
    unitsToOmit?: AlertNotificationRangeUnitType[];
}

const RangeSelector = (props: RangeSelectorPropsType): JSX.Element => {
    const classes = useStyles();

    const options = Object.values(AlertNotificationRangeUnitType).filter(
        (value) => !(props.unitsToOmit || []).includes(value),
    );

    return (
        <Box display="flex">
            <TextField
                type="number"
                className={classes.numberInput}
                variant="outlined"
                value={props.range.length !== undefined ? props.range.length : ''}
                error={!props.range.length}
                inputProps={{
                    style: {
                        textAlign: 'right',
                        width: '5ch',
                    },
                    min: 1,
                }}
                onChange={(event) =>
                    props.onChange({
                        length: event.target.value ? parseInt(event.target.value) : undefined,
                        unit: props.range.unit,
                    })
                }
            />

            <Select
                className={classes.select}
                variant="outlined"
                value={props.range.unit || options[0] || AlertNotificationRangeUnitType.DAYS}
                onChange={(event) =>
                    props.onChange({
                        length: props.range.length,
                        unit: event.target.value as AlertNotificationRangeUnitType,
                    })
                }
            >
                {Object.entries(options).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                        {value}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
};

export default RangeSelector;
