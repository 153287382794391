/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { Box, Button, Divider, IconButton, List, ListItem, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Add as AddIcon, Delete as DeleteIcon, InfoOutlined as InfoIcon } from '@mui/icons-material';
import { AlysAlertCondition, AlysMetric, AlysOperator } from '@webacq/wa-shared-definitions';
import AlertCondition from './AlertCondition';
import MetricSelector from './MetricSelector';

const useStyles = makeStyles((theme: Theme) => ({
    infoIcon: {
        marginLeft: theme.spacing(2),
        verticalAlign: 'middle',
        fontSize: 'inherit',
    },
    alertConditionsBox: {
        display: 'flex',
        flexDirection: 'column-reverse',
        marginLeft: theme.spacing(2),
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    alertCondition: {
        '&:hover': {
            backgroundColor: theme.palette.background.paper,
        },
    },
    infoTooltip: {
        maxWidth: 'none',
        paddingRight: theme.spacing(2),
    },
    addNewBox: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
    addNewLabel: {
        marginLeft: theme.spacing(2),
    },
    addNewMetric: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    addNewButton: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
        height: '100%',
    },
}));

interface AlertConditionsPropsType {
    metrics: AlysMetric[];
    operators: AlysOperator[];

    conditions: AlysAlertCondition[];
    onChange: (alertConditions: AlysAlertCondition[]) => void;
}

const AlertConditions = (props: AlertConditionsPropsType): JSX.Element => {
    const classes = useStyles();

    const [newMetricId, setNewMetricId] = React.useState<string>();

    return (
        <Box display="flex" flexDirection="column" height="100%" width="100%">
            <Typography variant="h6" gutterBottom>
                {`Conditions (${props.conditions.length})`}
                <Tooltip
                    title={
                        <ul style={{ fontSize: 12 }}>
                            <li>Notifications will be sent as long as one condition is met.</li>
                            <li>Post-processing errors will be alerted on automatically.</li>
                        </ul>
                    }
                    classes={{ tooltip: classes.infoTooltip }}
                >
                    <InfoIcon className={classes.infoIcon} />
                </Tooltip>
            </Typography>

            <Divider />

            <Box className={classes.alertConditionsBox}>
                <List disablePadding>
                    {props.conditions.map((condition, idx) => (
                        <ListItem
                            key={`alertCondition-${idx}`}
                            divider
                            disableGutters
                            className={classes.alertCondition}
                        >
                            <Box width="100%">
                                <AlertCondition
                                    metrics={props.metrics}
                                    operators={props.operators}
                                    condition={condition}
                                    onChange={(condition) => {
                                        const newConditions = [...props.conditions];
                                        newConditions[idx] = condition;
                                        props.onChange(newConditions);
                                    }}
                                />
                            </Box>
                            <IconButton
                                onClick={() => {
                                    const newConditions = [...props.conditions];
                                    newConditions.splice(idx, 1);
                                    props.onChange(newConditions);
                                }}
                                size="large"
                            >
                                <Tooltip title={<span style={{ fontSize: 12 }}>Delete Condition</span>}>
                                    <DeleteIcon fontSize="inherit" />
                                </Tooltip>
                            </IconButton>
                        </ListItem>
                    ))}
                </List>
            </Box>

            <Box display="flex" alignItems="center" className={classes.addNewBox}>
                <Typography className={classes.addNewLabel}>Add New Condition</Typography>

                <Box className={classes.addNewMetric}>
                    <MetricSelector
                        metrics={props.metrics}
                        allowEmpty
                        selectedMetricId={newMetricId}
                        onChange={(metricId) => setNewMetricId(metricId)}
                    />
                </Box>

                <Button
                    className={classes.addNewButton}
                    variant="outlined"
                    startIcon={<AddIcon />}
                    disabled={!newMetricId}
                    onClick={() => {
                        if (newMetricId) {
                            const newConditions = [...props.conditions];
                            newConditions.push({
                                metric: newMetricId,
                                operator: props.operators[0].id,
                                operands: Array.from({ length: props.operators[0].arity - 1 }).map((_, idx) => idx),
                            });
                            props.onChange(newConditions);
                            setNewMetricId(undefined);
                        }
                    }}
                >
                    Add
                </Button>
            </Box>
        </Box>
    );
};

export default AlertConditions;
