/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { Box, Collapse, Grid, IconButton, Theme, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
    Delete as DeleteIcon,
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
    Edit as PencilIcon,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { AlysAlertCondition, AlysMetric, AlysOperator } from '@webacq/wa-shared-definitions';
import AlertConditions from './AlertConditions/AlertConditions';
import AlertNotification from './AlertNotification/AlertNotification';
import AlertOptions from './AlertOptions';
import ConfirmationDialog from './ConfirmationDialog';
import { UserInfoType } from './AlertNotification/UserInput';
import { AlertConfigurationData, CustomAlertOption, maxAlertOptionNameLength, pluralSuffix } from './utils';
import { AlertNotificationOccurrenceType, AlertNotificationType, AlertOptionType } from './types';

const useStyles = makeStyles((theme: Theme) => ({
    mainBox: {
        width: '100%',
        borderStyle: 'solid',
        borderColor: 'white',
        borderWidth: 2,
        marginBottom: theme.spacing(2),
    },
    title: {
        backgroundColor: theme.palette.background.paper,
    },
    configBox: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: '100%',
    },
    optionsBox: {
        padding: theme.spacing(2),
    },
    summary: {
        marginLeft: theme.spacing(5),
    },
    summaryDescription: {
        marginLeft: theme.spacing(2),
        color: theme.palette.grey[300],
    },

    conditionsBox: {
        padding: theme.spacing(2),
        width: '100%',
        maxHeight: '40vh',
        overflow: 'clip',
    },
    notificationsBox: {
        padding: theme.spacing(2),
        width: '100%',
        borderStyle: 'solid',
        borderColor: 'white',
        borderLeftWidth: 2,
        borderWidth: 0,
        overflowY: 'auto',
        overflowX: 'clip',
    },
    notificationsBoxNoBorder: {
        padding: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: '100%',
        overflowY: 'auto',
        overflowX: 'clip',
    },
}));

export interface SingleAlertConfigurationPropsType {
    cfgIdx: number;

    alertOptions: AlertOptionType[];
    alertMetrics: AlysMetric[];
    alertConditionOperators: AlysOperator[];

    alertConfig: AlertConfigurationData;

    collapsed?: boolean;
    useTransition?: boolean;
    modified: boolean;

    onLoadAlertConfig: (alertConfigId: string) => Promise<AlertConfigurationData | undefined>;
    onQueryUsers: (query: string, maxRecords: number) => Promise<{ matches: UserInfoType[]; errormsg?: string }>;
    onChange: (alertConfig: AlertConfigurationData, modified: boolean) => void;
    onCollapsedChanged: (collapsed: boolean) => void;
    onDelete: () => void;
}

const SingleAlertConfiguration = (props: SingleAlertConfigurationPropsType): JSX.Element => {
    const classes = useStyles();
    const theme = useTheme();

    const isSmall = useMediaQuery(theme.breakpoints.down('xl'));

    const [loadTemplateConfirmationDialogArgs, setLoadTemplateConfirmationDialogArgs] = React.useState<{
        title: string;
        confirmAction: { label: string; onClick: () => void };
    }>();

    const alertConfigOptionChanged = (alertOptionId: string): void => {
        if (alertOptionId === CustomAlertOption.id) {
            const newAlertConfig = props.alertConfig.clone();

            // if old alertConfig is a template => reset id
            if (props.alertConfig.isTemplate) {
                newAlertConfig.alertConfigId = '';
                newAlertConfig.isTemplate = false;
            }

            props.onChange(newAlertConfig, true);
        } else {
            // load a template

            const loadAlertConfig = () =>
                props.onLoadAlertConfig(alertOptionId).then((newAlertConfig) => {
                    if (newAlertConfig) {
                        props.onChange(newAlertConfig, false);
                    }
                });

            if (props.alertConfig.isTemplate) {
                loadAlertConfig();
            } else {
                setLoadTemplateConfirmationDialogArgs({
                    title: 'Load the template and lose your current changes?',
                    confirmAction: {
                        label: 'Load Template',
                        onClick: loadAlertConfig,
                    },
                });
            }
        }
    };

    const updateAlertConfigConditions = (conditions: AlysAlertCondition[]) => {
        const newAlertConfig = props.alertConfig.clone();

        newAlertConfig.conditions = conditions;

        // if current alertConfig is a template => reset id
        if (props.alertConfig.isTemplate) {
            newAlertConfig.alertConfigId = '';
            newAlertConfig.isTemplate = false;
        }

        props.onChange(newAlertConfig, true);
    };

    const updateAlertConfigNotifications = (
        occurrenceConfig: AlertNotificationOccurrenceType,
        notifications: AlertNotificationType,
    ): void => {
        const newAlertConfig = props.alertConfig.clone();

        newAlertConfig.occurrenceConfig = occurrenceConfig;
        newAlertConfig.notifications = notifications;

        // if current alertConfig is a template => reset id
        if (props.alertConfig.isTemplate) {
            newAlertConfig.alertConfigId = '';
            newAlertConfig.isTemplate = false;
        }

        props.onChange(newAlertConfig, true);
    };

    const useGrid = true;

    return (
        <Box className={classes.mainBox}>
            <Box display="flex" flexDirection="row" alignItems="center" className={classes.title}>
                <Box flexGrow={1}>
                    <IconButton onClick={() => props.onCollapsedChanged(!props.collapsed)} size="large">
                        {props.collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                    </IconButton>

                    <Typography display="inline" noWrap>
                        {`Alert Configuration ${props.cfgIdx + 1}`}
                        <span
                            style={{
                                display: 'inline-block',
                                width: '1ch',
                                textAlign: 'right',
                            }}
                        >
                            {props.modified && (
                                <Tooltip title="This alert configuration has been modified">
                                    <span>*</span>
                                </Tooltip>
                            )}
                        </span>
                    </Typography>

                    {props.collapsed && (
                        <>
                            <Box width={`${maxAlertOptionNameLength(props.alertOptions)}ch`} display="inline-block">
                                <Typography display="inline" variant="body2" className={classes.summary}>
                                    {props.alertOptions.find((option) => option.id === props.alertConfig.alertConfigId)
                                        ?.name || (
                                        <>
                                            <PencilIcon fontSize="inherit" />
                                            &nbsp;&nbsp;{`${CustomAlertOption.name}`}
                                        </>
                                    )}
                                </Typography>
                            </Box>
                            <Box display="inline-block">
                                <Typography display="inline" variant="body2" className={classes.summaryDescription}>
                                    {`(${
                                        props.alertOptions.find(
                                            (option) => option.id === props.alertConfig.alertConfigId,
                                        )?.description || CustomAlertOption.description
                                    }. ${props.alertConfig.conditions.length} condition${pluralSuffix(
                                        props.alertConfig.conditions.length,
                                    )}${props.alertConfig.notifications.createWorkItem ? ', create work item' : ''}${
                                        props.alertConfig.notifications.sendMsgSettings.enabled
                                            ? ', send MSG alerts'
                                            : ''
                                    })`}
                                </Typography>
                            </Box>
                        </>
                    )}
                </Box>
                <IconButton onClick={() => props.onDelete()} size="large">
                    <DeleteIcon />
                </IconButton>
            </Box>
            <Collapse in={!props.collapsed} timeout={{ enter: props.useTransition ? 200 : 0, exit: 200 }}>
                <Box display="flex" flexDirection="column" className={classes.configBox}>
                    <Box className={classes.optionsBox}>
                        <AlertOptions
                            options={props.alertOptions}
                            selectedId={props.alertConfig.alertConfigId}
                            onChange={(alertOptionId) => alertConfigOptionChanged(alertOptionId)}
                        />
                    </Box>

                    {useGrid ? (
                        <Grid container>
                            <Grid item className={classes.conditionsBox} lg={12} xl={6}>
                                <AlertConditions
                                    metrics={props.alertMetrics}
                                    operators={props.alertConditionOperators}
                                    conditions={props.alertConfig.conditions}
                                    onChange={(conditions) => updateAlertConfigConditions(conditions)}
                                />
                            </Grid>
                            <Grid
                                item
                                className={isSmall ? classes.notificationsBoxNoBorder : classes.notificationsBox}
                                lg={12}
                                xl={6}
                            >
                                <AlertNotification
                                    occurrenceConfig={props.alertConfig.occurrenceConfig}
                                    notifications={props.alertConfig.notifications}
                                    onQueryUsers={props.onQueryUsers}
                                    onChange={(occurrenceConfig, notifications) =>
                                        updateAlertConfigNotifications(occurrenceConfig, notifications)
                                    }
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <Box display="flex" flexGrow={1} minHeight={0}>
                            <Box display="flex" height="100%" width="100%">
                                <Box width="42%" className={classes.conditionsBox}>
                                    <AlertConditions
                                        metrics={props.alertMetrics}
                                        operators={props.alertConditionOperators}
                                        conditions={props.alertConfig.conditions}
                                        onChange={(conditions) => updateAlertConfigConditions(conditions)}
                                    />
                                </Box>
                                <Box width="58%" className={classes.notificationsBox}>
                                    <AlertNotification
                                        occurrenceConfig={props.alertConfig.occurrenceConfig}
                                        notifications={props.alertConfig.notifications}
                                        onQueryUsers={props.onQueryUsers}
                                        onChange={(occurrenceConfig, notifications) =>
                                            updateAlertConfigNotifications(occurrenceConfig, notifications)
                                        }
                                    />
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Collapse>

            <ConfirmationDialog
                title={loadTemplateConfirmationDialogArgs?.title || ''}
                isOpen={!!loadTemplateConfirmationDialogArgs}
                buttons={[
                    {
                        label: loadTemplateConfirmationDialogArgs?.confirmAction.label || '',
                        onClick: () => {
                            if (loadTemplateConfirmationDialogArgs?.confirmAction) {
                                loadTemplateConfirmationDialogArgs.confirmAction.onClick();
                            }
                            setLoadTemplateConfirmationDialogArgs(undefined);
                        },
                    },
                    {
                        label: "Don't",
                        onClick: () => setLoadTemplateConfirmationDialogArgs(undefined),
                        focus: true,
                    },
                ]}
                onClose={() => setLoadTemplateConfirmationDialogArgs(undefined)}
            />
        </Box>
    );
};

export default SingleAlertConfiguration;
